import React, { FC, useCallback, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import styled, { ThemeContext } from 'styled-components';
import { useQuery } from 'react-query';
import { useFeatureFlag } from 'configcat-react';

import { TestIds } from '@investown/fe/test-utils/testIds';
import { getLatestAutoinvestStrategy, QueryKeys, queryStaleTimes } from '@investown/fe/api-sdk';

import { MenuButton } from '../components/MenuButton';
import { fillColor, textColor } from '../../utils';
import AutoinvestGuideModal from '../AutoinvestGuideModal/AutoinvestGuideModal';

import { PATH_AUTOINVEST, PATH_AUTOINVEST_INTRO } from 'routes/routesPaths';
import AutoinvestIcon from 'components/V2/Icons/Autoinvest';
import { FallbackFeatureFlagValues, FeatureFlags } from 'constants/FeatureFlags';

const AutoinvestMenuIcon: FC = () => {
  const { pathname } = useLocation();
  const theme = useContext(ThemeContext);
  const isAutoinvestActive = PATH_AUTOINVEST === pathname;

  const autoinvestStrategy = useQuery(QueryKeys.LatestAutoinvestStrategy, getLatestAutoinvestStrategy, {
    staleTime: queryStaleTimes.fiveMinutes,
  });

  const getCorrectAutoinvestUrl = useCallback(() => {
    if (autoinvestStrategy.data?.getLatestAutoinvestStrategy == null) {
      return PATH_AUTOINVEST_INTRO;
    }
    return PATH_AUTOINVEST;
  }, [autoinvestStrategy.data?.getLatestAutoinvestStrategy]);

  const showAutoinvestWeb = useFeatureFlag<boolean>(
    FeatureFlags.ShowAutoinvestWeb,
    FallbackFeatureFlagValues[FeatureFlags.ShowAutoinvestWeb]
  );
  const showAutoinvestWebLoadedAndEnabled = !showAutoinvestWeb.loading && showAutoinvestWeb.value;

  if (!showAutoinvestWebLoadedAndEnabled) {
    return null;
  }

  return (
    <AutoinvestContainer>
      <AutoinvestButton to={getCorrectAutoinvestUrl()} $active={isAutoinvestActive} data-testid={TestIds.Autoinvest}>
        <AutoinvestIcon fillColor={fillColor(isAutoinvestActive, theme)} color={textColor(isAutoinvestActive, theme)} />
      </AutoinvestButton>
      <AutoinvestGuideModal />
    </AutoinvestContainer>
  );
};

const AutoinvestButton = styled(MenuButton)`
  padding: ${({ theme }) => theme.spacing.small} ${({ theme }) => theme.spacing.medium};
  height: 100%;
`;

const AutoinvestContainer = styled.div`
  position: relative;
`;

export default AutoinvestMenuIcon;
