import Icon, { IconProps } from 'antd/es/icon';
import React, { FC } from 'react';

const AntdIcon: FC<IconProps> = ({ ...rest }) => (
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Icon {...rest} />
);
export default AntdIcon;
